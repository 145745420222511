import * as React from 'react'
import { PageProps } from 'gatsby'

import Layout from '../containers/layout'
import Header from '../components/header'
import Footer from '../components/footer'

const PrivacyPolicyEnPage: React.FC<PageProps> = ({ location, pageContext }) => (
  <Layout id="content" subtitle="Privacy Policy    " pageContext={pageContext}>
    <section id="top">
      <Header location={location} pageContext={pageContext} />
    </section>

    <div className="content-wrap">
      <section className="privacy">
        <article>
          <h2>Privacy Policy</h2>
          <p>
            Embody Me Co., Ltd. (hereinafter referred to as &ldquo;the
            Company&rdquo;) recognizes the importance of protecting personal
            information and complies with the Act on the Protection of Personal
            Information (hereinafter referred to as the &ldquo;Personal
            Information Protection Law&rdquo;). We will endeavor to handle and
            protect the information appropriately in accordance with our privacy
            policy (hereinafter referred to as &ldquo;this privacy
            policy&rdquo;). Unless otherwise specified in this privacy policy,
            the definitions of terms in this privacy policy are in accordance
            with the provisions of the Personal Information Protection Law.
          </p>
          <p>
            <u>1.</u>
            <u>Definition of personal information</u>
          </p>
          <p>
            In this privacy policy, personal information refers to information
            about living individuals that falls under any of the following
            items.
          </p>
          <p>
            (1) &nbsp;&nbsp;&nbsp; Name, date of birth and other descriptions
            contained in the information (all matters described or recorded in
            documents, drawings or electromagnetic records, or expressed using
            voice, movement or other methods) Those that can identify a specific
            individual by (referred to as) (including those that can be easily
            collated with other information and thereby identify a specific
            individual)
          </p>
          <p>
            (2) &nbsp;&nbsp;&nbsp; Those that include a personal identification
            code
          </p>
          <p>
            <u>2.</u>
            <u>Purpose of use of personal information</u>
          </p>
          <p>We use personal information for the following purposes.</p>
          <p>
            (1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To provide Xpression, EmbodyMe and
            other services of our company
          </p>
          <p>
            (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For information on Xpression,
            EmbodyMe and other services of our company, and for responding to
            inquiries, etc.
          </p>
          <p>
            (3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To respond to acts that violate
            our terms, policies, etc. (hereinafter referred to as &ldquo;terms,
            etc.&rdquo;) regarding Xpression, EmbodyMe and other services of our
            company.
          </p>
          <p>
            (4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To notify changes to Xpression,
            EmbodyMe and other terms and conditions related to our services
          </p>
          <p>
            (5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To help improve Xpression,
            EmbodyMe and other services of our company, develop new services,
            etc.
          </p>
          <p>
            (6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For employment management and
            internal procedures (personal information of officers and employees)
          </p>
          <p>
            (7)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For shareholder management,
            corporate law and other legal procedures (personal information of
            shareholders, stock acquisition rights holders, etc.)
          </p>
          <p>
            (8)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To create statistical data
            processed into an unidentifiable format related to Xpression,
            EmbodyMe and other services of our company.
          </p>
          <p>
            (9)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For other purposes incidental to
            the above purpose of use
          </p>
          <p>
            <u>3.</u>
            <u>Change of purpose of use of personal information</u>
          </p>
          <p>
            The Company may change the purpose of use of personal information
            within the range reasonably recognized as relevant, and if changed,
            the individual who is the subject of the personal information
            (hereinafter referred to as &ldquo;the person&rdquo;). Will be
            notified or announced.
          </p>
          <p>
            <u>4.</u>
            <u>Restrictions on the use of personal information</u>
          </p>
          <p>
            We will not handle personal information beyond the scope necessary
            to achieve the purpose of use without obtaining the consent of the
            person, except when permitted by the Personal Information Protection
            Law and other laws and regulations. However, this does not apply in
            the following cases.
          </p>
          <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When required by law</p>
          <p>
            (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When it is necessary to protect
            the life, body or property of a person and it is difficult to obtain
            the consent of the person.
          </p>
          <p>
            (3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When it is particularly necessary
            to improve public health or promote the sound development of
            children, and it is difficult to obtain the consent of the person.
          </p>
          <p>
            (4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When it is necessary for a
            national institution or a local public body or a person entrusted
            with it to cooperate in carrying out the affairs stipulated by laws
            and regulations, and the affairs are carried out with the consent of
            the person. When there is a risk of interfering with
          </p>
          <p>
            <u>5.</u>
            <u>Appropriate acquisition of personal information</u>
          </p>
          <p>
            5.1&nbsp;&nbsp;&nbsp;&nbsp; We will properly acquire personal
            information and will not acquire it by deception or other improper
            means.
          </p>
          <p>
            5.2&nbsp;&nbsp;&nbsp;&nbsp; The Company will not acquire sensitive
            personal information (meaning what is defined in Article 2,
            Paragraph 3 of the Personal Information Protection Law) without
            obtaining the consent of the person in advance, except in the
            following cases.
          </p>
          <p>
            (1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When any of the items in Paragraph
            4 is applicable
          </p>
          <p>
            (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The sensitive personal information
            is disclosed by the person, the national institution, the local
            public body, the person listed in each item of Article 76, Paragraph
            1 of the Personal Information Protection Law, and other persons
            specified by the rules of the Personal Information Protection
            Commission. case
          </p>
          <p>
            (3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When acquiring sensitive personal
            information that is clear on the outside by visually observing or
            photographing the person
          </p>
          <p>
            (4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When the personal information
            requiring consideration is provided by a third party and the
            provision by the third party falls under any of the items in Section
            7.1.
          </p>
          <p>
            5.3&nbsp;&nbsp;&nbsp;&nbsp; When receiving personal information from
            a third party, the Company will confirm the following matters in
            accordance with the rules of the Personal Information Protection
            Commission. However, this does not apply to cases where the
            provision of the personal information by the third party falls under
            any of the items in Paragraph 4 or any of the items in Paragraph
            7.1.
          </p>
          <p>
            (1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The name or name and address of
            the third party, and in the case of a corporation, the name of its
            representative (in the case of a non-corporate organization with a
            designated representative or administrator, its representative or
            administrator)
          </p>
          <p>
            (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Background of acquisition of the
            personal information by the third party
          </p>
          <p>
            5.4&nbsp; &nbsp; &nbsp;Xpression uses the True Depth API for
            real-time facial expression tracking. It does not hold the
            information obtained from the True Depth API.
          </p>
          <p>
            <u>6.</u>
            <u>Security management of personal information</u>
          </p>
          <p>
            We will supervise our employees as necessary and appropriate so that
            personal information can be safely managed against risks such as
            loss, destruction, falsification and leakage of personal
            information. In addition, when we outsource all or part of the
            handling of personal information, we will perform necessary and
            appropriate supervision so that the outsourcee can manage the
            security of personal information.
          </p>
          <p>
            <u>7.</u>
            <u>Provided by a third party</u>
          </p>
          <p>
            7.1&nbsp;&nbsp;&nbsp;&nbsp; The Company will not provide personal
            information to a third party without the prior consent of the
            individual, except in cases where any of the items in Paragraph 4
            applies. However, the following cases do not correspond to the
            provision to the third party specified above.
          </p>
          <p>
            (1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When providing personal
            information by entrusting all or part of the handling of personal
            information to the extent necessary to achieve the purpose of use
          </p>
          <p>
            (2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When personal information is
            provided due to business succession due to merger or other reasons
          </p>
          <p>
            (3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When sharing based on the
            provisions of the Personal Information Protection Law
          </p>
          <p>
            7.2&nbsp;&nbsp;&nbsp;&nbsp; Notwithstanding the provisions of
            Paragraph 7.1, the Company shall be designated by the rules of the
            Personal Information Protection Commission based on Article 24 of
            the Personal Information Protection Law, except when it falls under
            any of the items of Paragraph 4. Personal information to a third
            party (excluding those who have established a system that conforms
            to the standards specified by the rules of the Personal Information
            Protection Commission based on Article 24 of the Personal
            Information Protection Law) in (excluding the country) In the case
            of providing, the consent of the person to approve the provision to
            a third party in a foreign country shall be obtained in advance.
          </p>
          <p>
            7.3&nbsp;&nbsp;&nbsp;&nbsp; When we provide personal information to
            a third party, we will create and store records in accordance with
            Article 25 of the Personal Information Protection Law.
          </p>
          <p>
            7.4&nbsp;&nbsp;&nbsp;&nbsp; When receiving personal information from
            a third party, the Company shall perform necessary confirmations in
            accordance with Article 26 of the Personal Information Protection
            Law, and create and store records related to the confirmations.
          </p>
          <p>
            <u>8.</u>
            <u>Disclosure of personal information</u>
          </p>
          <p>
            When the person requests the disclosure of personal information
            based on the provisions of the Personal Information Protection Law,
            we will disclose it to the person without delay after confirming
            that the request is from the person himself / herself. (If the
            personal information does not exist, we will notify you to that
            effect). However, this does not apply if the Company is not
            obligated to disclose due to the Personal Information Protection Law
            and other laws and regulations.
          </p>
          <p>
            <u>9.</u>
            <u>Correction of personal information, etc.</u>
          </p>
          <p>
            If the person requests correction, addition or deletion (hereinafter
            referred to as &ldquo;correction, etc.&rdquo;) of the content based
            on the provisions of the Personal Information Protection Law because
            the personal information is not true, the Company will do so. After
            confirming that the request was made by the person himself /
            herself, we will conduct the necessary investigation without delay
            within the range necessary to achieve the purpose of use, and based
            on the result, correct the content of personal information, etc. We
            will notify the person to that effect (if we decide not to make
            corrections, we will notify the person to that effect). However,
            this does not apply if the Company is not obligated to make
            corrections, etc. under the Personal Information Protection Law and
            other laws and regulations.
          </p>
          <p>
            <u>10.</u>
            <u>Suspension of use of personal information, etc.</u>
          </p>
          <p>
            Our company is the Personal Information Protection Law because the
            personal information of the person is handled beyond the scope of
            the purpose of use announced in advance or because it was obtained
            by deception or other improper means. If you are requested to
            suspend or delete the use (hereinafter referred to as
            &ldquo;suspension of use, etc.&rdquo;) based on the provisions of,
            or because personal information is provided to a third party without
            the consent of the individual, the individual If you are requested
            to suspend the provision (hereinafter referred to as
            &ldquo;suspension of provision&rdquo;) based on the provisions of
            the Information Protection Law, and if it becomes clear that there
            is a reason for the request, you will be asked to do so. After
            confirming that the request has been made, we will suspend the use
            or provision of personal information without delay and notify the
            person to that effect. However, this does not apply if the Company
            is not obligated to suspend the use or provision of services under
            the Personal Information Protection Law and other laws and
            regulations.
          </p>
          <p>
            <u>11.</u>
            <u>Handling of anonymously processed information</u>
          </p>
          <p>
            11.1&nbsp;&nbsp; Our company means anonymously processed information
            (meaning what is stipulated in Article 2, Paragraph 9 of the
            Personal Information Protection Law, and is limited to those that
            constitute the anonymously processed information database, etc.
            stipulated in Article 2, Paragraph 10 of the same law. The same
            shall apply hereinafter. When creating.), Personal information shall
            be processed in accordance with the standards stipulated by the
            rules of the Personal Information Protection Committee.
          </p>
          <p>
            11.2&nbsp;&nbsp; When we create anonymously processed information,
            we will take measures for safety management in accordance with the
            standards stipulated by the rules of the Personal Information
            Protection Commission.
          </p>
          <p>
            11.3&nbsp;&nbsp; When we create anonymously processed information,
            we will publish the items of personal information included in the
            anonymously processed information in accordance with the rules of
            the Personal Information Protection Commission.
          </p>
          <p>
            11.4&nbsp;&nbsp; When providing anonymously processed information
            (including information created by the Company and information
            provided by a third party; the same shall apply hereinafter unless
            otherwise specified) to a third party, the Company shall be an
            individual. According to the rules of the Information Protection
            Commission, the items of personal information contained in the
            anonymously processed information provided to a third party and the
            method of providing the information will be announced in advance,
            and the information will be provided to the third party. It is
            clearly stated that the information related to is anonymously
            processed information.
          </p>
          <p>
            11.5&nbsp;&nbsp; When handling anonymously processed information, in
            order to identify the person related to the personal information
            used to create the anonymously processed information, (1) collate
            the anonymously processed information with other information, and
            (2) ) Obtaining information regarding the description, etc. deleted
            from the personal information, personal identification code, or
            processing method performed pursuant to the provisions of Article
            36, Paragraph 1 of the Personal Information Protection Law ((2) is
            provided by a third party. (Only for the anonymously processed
            information that has been received) shall not be performed.
          </p>
          <p>
            11.6&nbsp;&nbsp; The Company takes necessary and appropriate
            measures for the safety management of anonymously processed
            information, handling of complaints regarding the creation of
            anonymously processed information and other handling, and other
            necessary measures to ensure the proper handling of anonymously
            processed information. We shall endeavor to take measures and
            publicize the details of the measures.
          </p>
          <p>
            <u>12.</u>
            <u>Use of cookies and other technologies</u>
          </p>
          <p>
            Our services may use cookies and similar technologies. These
            technologies help us to understand the usage status of our services
            and contribute to the improvement of our services. Users who want to
            disable cookies can disable cookies by changing the settings of
            their web browser. However, if you disable cookies, you may not be
            able to use some functions of our service.
          </p>
          <p>
            <u>13.</u>
            <u>Inquiries</u>
          </p>
          <p>
            For requests for disclosure, opinions, questions, complaints, and
            other inquiries regarding the handling of personal information,
            please contact the following.
          </p>
          <p>
            3-23-3 Takadanobaba, Shinjuku-ku, Tokyo 169-0075 OR Building M2F
          </p>
          <p>EmbodyMe, Inc.</p>
          <p>E-mail&nbsp;&nbsp;&nbsp;&nbsp; ：info@embodyme.com</p>
        </article>
      </section>
    </div>

    <Footer location={location} />
  </Layout>
)

export default PrivacyPolicyEnPage
